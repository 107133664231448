import { myBusinessReport, myExFansList, myRecommendAmountList, myReturnAmountList } from "@/utils/user.js"
export default {
    data() {
        return {
            timeTitle: "",
            timeShow: false,
            currentDate: new Date(),
            list: [],
            active: 0,
            loading: false,
            finished: false,
            busInfo: {},
            startDate: "",
            endDate: "",
            pageNo: 1,
            pageSize: 10,
            name: ""
        }
    },
    created() {
        // 跳转登录页面
        // if (!this.$cookies.get('isLogin')) {
        //     this.$router.push({
        //         path: '/login'
        //     })
        // } else {
            this.getBussiness();
            this.getList();
        // }
    },
    methods: {
        getList() {
            const data = {
                userId: this.$cookies.get('userId'),
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }
            if (this.name) {
                data.name = this.name
            }
            // 地推业绩列表myExFansList，推荐佣金列表myRecommendAmountList,返佣佣金列表myReturnAmountList
            const apiFun = this.active === 0 ? myExFansList : (this.active === 1 ? myReturnAmountList : myRecommendAmountList)
            apiFun(data).then(res => {
                this.loading = false;
                if (res) {
                    if (res.code == "0") {
                        let arr = []
                        if (res && res.data) {
                            if (this.active === 0) {
                                arr = res.data.memberList ? res.data.memberList : []
                            } else {
                                arr = res.data.dataList ? res.data.dataList : []
                            }
                        }
                        this.list = this.list.concat(arr)
                        if (arr.length < this.pageSize) {
                            this.finished = true;
                        } else {
                            this.pageNo++;
                        }
                    } else {
                        this.finished = true;
                    }
                } else {
                    this.finished = true;
                }
            })
        },
        onLoad() {
            this.loading = true
                // 异步更新数据
            setTimeout(() => {
                this.getList();
            }, 300);
        },
        getBussiness() {
            let data = {
                userId: this.$cookies.get('userId'),
            }
            if (this.startDate && this.endDate) {
                const s = new Date(this.startDate).getTime()
                const e = new Date(this.endDate).getTime()
                if (s > e) {
                    return this.$toast.fail('结束时间要大于起始时间')
                }
            }
            if (this.startDate) {
                data.startDate = this.startDate.replace(/\//g, '-')
            }
            if (this.endDate) {
                data.endDate = this.endDate.replace(/\//g, '-')
            }
            myBusinessReport(data).then(res => {
                if (res && res.code == "0") {
                    this.busInfo = res.data || {}
                }
            })
        },
        timeFun(val) {
            this.timeShow = true
            this.timeTitle = val === 1 ? "起始时间" : "结束时间"
        },
        timeConfirm(val) {
            this.timeShow = false
            if (this.timeTitle == "起始时间") {
                this.startDate = this.$parseTime(val, '{y}/{m}/{d}')
            } else {
                this.endDate = this.$parseTime(val, '{y}/{m}/{d}')
            }
            this.getBussiness()
        },
        $parseTime(time, cFormat) {
            if (!time) return
            const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
            let date = null
            if (typeof time === 'object') {
                date = time
            } else {
                if (('' + time).length === 10) time = parseInt(time) * 1000
                date = new Date(time)
            }
            const formatObj = {
                y: date.getFullYear(),
                m: date.getMonth() + 1,
                d: date.getDate(),
                h: date.getHours(),
                i: date.getMinutes(),
                s: date.getSeconds(),
                a: date.getDay()
            }
            const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
                let value = formatObj[key]
                    // Note: getDay() returns 0 on Sunday
                if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
                if (result.length > 0 && value < 10) {
                    value = '0' + value
                }
                return value || 0
            })
            return time_str
        },
        timeCancel() {
            this.timeShow = false
        },
        formatter(type, val) {
            if (type === 'year') {
                return val + '年';
            }
            if (type === 'month') {
                return val + '月';
            }
            if (type === 'day') {
                return val + '日';
            }
            return val;
        },
        searchFun() {
            this.pageNo = 1
            this.list = []
            this.finished = false
            this.loading = true
            this.getList()
        },
        activeFun(num) {
            this.active = num
            this.pageNo = 1
            this.list = []
            this.finished = false
            this.loading = true
            this.getList()
        }
    },
}
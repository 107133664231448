<template>
  <div class="business-overview">
    <div class="t-hold">
      <div class="header-box">
        <div class="header flex align-center">
          <p class="all">全部累计</p>
          <div class="select select1 flex align-center" @click="timeFun(1)">
            <img src="@/assets/img/business/date.png" class="date" />
            <input v-model="startDate" placeholder="起始时间" readonly />
            <img src="@/assets/img/business/right.png" class="right" />
          </div>
          <p class="to">～</p>
          <div class="select select2 flex align-center" @click="timeFun(2)">
            <img src="@/assets/img/business/date.png" class="date" />
            <input v-model="endDate" placeholder="结束时间" readonly />
            <img src="@/assets/img/business/right.png" class="right" />
          </div>
        </div>
      </div>
      <div class="main">
        <div class="third-box flex flex-between">
          <div
            :class="['b-b', { 'b-red': active === 0 }]"
            @click="activeFun(0)"
          >
            <p>发展达人数</p>
            <p>{{ busInfo.fans || 0 }}</p>
          </div>
          <div
            :class="['b-b', { 'b-red': active === 1 }]"
            @click="activeFun(1)"
          >
            <p>累计获得销售佣金</p>
<!--            <p>¥{{ busInfo.sellSum || 0 }}</p>-->
            <p style="font-size:12px;">由代理商为您结算</p>
          </div>
          <div
            :class="['b-b', { 'b-red': active === 2 }]"
            @click="activeFun(2)"
          >
            <p>获得推荐佣金</p>
<!--            <p>¥{{ busInfo.inviteSum || 0 }}</p>-->
            <p style="font-size:12px;">由代理商为您结算</p>
          </div>
        </div>
        <p class="desc">已结算金额需要咨询所属的代理商，由代理商为您结算</p>
        <p class="title">业务明细</p>
        <div class="b-holder">
          <div class="i-box flex align-center">
            <div class="i-l flex align-center">
              <img src="@/assets/img/business/search.png" alt="" />
              <input v-model="name" type="text" placeholder="搜索关键词" />
            </div>
            <p class="search" @click="searchFun">搜索</p>
          </div>
          <template v-if="active === 0">
            <ul class="top flex">
              <li>昵称</li>
              <li>手机号</li>
              <li>状态</li>
              <li>成为达人时间</li>
            </ul>
          </template>
          <template v-else-if="active === 1">
            <ul class="top second flex">
              <li>昵称</li>
              <li>实付商品金额</li>
              <!-- <li>佣金比例</li> -->
              <li>销售佣金</li>
            </ul>
          </template>
          <template v-else>
            <ul class="top third flex">
              <li>昵称</li>
              <li>完成时间</li>
              <li>推荐佣金</li>
            </ul>
          </template>
        </div>
      </div>
    </div>
    <div class="content">
      <ul
        :class="[
          { bottom: active === 0 },
          { 'second-bottom': active === 1 },
          { 'third-bottom': active === 2 },
        ]"
      >
        <van-list
          v-model:loading="loading"
          :finished="finished"
          offset="25"
          :immediate-check="false"
          @load="onLoad"
        >
          <template v-if="active === 0">
            <li
              v-for="(item, index) in list"
              :key="index"
              :class="['flex align-center', {'last-li': index == list.length - 1 && finished}]"
            >
              <p>{{ item.name }}</p>
              <p>{{ item.phone }}</p>
              <p>成功</p>
              <p>
                {{ $parseTime(item.createTalentTime, "{y}-{m}-{d} {h}:{i}") }}
              </p>
            </li>
          </template>
          <template v-else-if="active === 1">
            <li v-for="(item, index) in list" :key="index" :class="[{'last-li': index == list.length - 1 && finished}]">
              <div class="l-t flex align-center">
                <p>{{ item.name }}</p>
                <p>{{ item.price }}</p>
                <!-- <p>{{ item.three }}</p> -->
<!--                <p>¥{{ item.can_amount }}</p>-->
                <p>由代理商为您结算</p>
              </div>
              <p class="time">完成时间：{{ item.gmt_create }}</p>
            </li>
          </template>
          <template v-else>
            <li
              v-for="(item, index) in list"
              :key="index"
              :class="['flex align-center', {'last-li': index == list.length - 1 && finished}]"
            >
              <p>{{ item.name }}</p>
              <p>{{ item.gmt_create }}</p>
<!--              <p>¥{{ item.can_amount }}</p>-->
              <p>由代理商为您结算</p>
            </li>
          </template>
          <li v-if="list.length == 0 && finished" class="flex align-center no-data"><p>暂无数据</p></li>
        </van-list>
      </ul>
    </div>
    <van-popup
      v-model:show="timeShow"
      position="bottom"
      :style="{ width: '100%' }"
      :close-on-click-overlay="false"
      class="time-popup"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :title="timeTitle"
        :columns-order="['year', 'month', 'day']"
        :formatter="formatter"
        @confirm="timeConfirm"
        @cancel="timeCancel"
      />
    </van-popup>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.business-overview {
  .van-loading__text{
    font-size: 12px !important;
  }
  .van-picker__toolbar button {
    color: #e62129 !important;
  }
  .content{
    .van-list{
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      border-radius: 0 0 10px 10px;
    }
    .van-list__loading {
      background: #ffffff !important;
      border-radius: 0 0 10px 10px;
    }
  }
}
</style>
